import { sliders } from "../components/Homepage/Sliders/SubSliders.lang.en";
import { homepage } from "../components/Homepage/SmartHomepage.lang.en";
import { compatibilities } from "../components/Homepage/Compatibilities/SubCompatibilities.lang.en";
import { presentation } from "../components/Homepage/Presentation/SubPresentation.lang.en";
import { faq } from "../components/Homepage/FAQ/SubFAQ.lang.en";
import { common } from "./common/common.lang.en";
import { creditCardForm } from "../components/CreditCardInformation/CreditCardForm/SubCreditCardForm.lang.en";
import { contact } from "../components/Footer/FooterLinks/Contact/SmartContact.lang.en";
import { faqPage } from "../components/Footer/FooterLinks/FAQ/SmartFAQ.lang.en";
import { helpCenter } from "../components/Footer/FooterLinks/Help/SmartHelp.lang.en";
import { jobs } from "../components/Footer/FooterLinks/Jobs/SmartJobs.lang.en";
import { legalNotices } from "../components/Footer/FooterLinks/LegalNotices/SmartLegalNotices.lang.en";
import { privacy } from "../components/Footer/FooterLinks/Privacy/SmartPrivacy.lang.en";
import { terms } from "../components/Footer/FooterLinks/Terms/SmartTerms.lang.en";
import { forgotPassword } from "../components/ForgotPassword/SmartForgotPassword.lang.en";
import { managePaymentMethods } from "../components/ManagePaymentMethods/SmartManagePaymentMethods.lang.en";
import { payProfileForm1 } from "../components/PaymentProfile/Forms/PayProfileForm1/PayProfileForm1.lang.en";
import { payProfileForm2 } from "../components/PaymentProfile/Forms/PayProfileForm2/PayProfileForm2.lang.en";
import { payProfileForm3 } from "../components/PaymentProfile/Forms/PayProfileForm3/PayProfileForm3.lang.en";
import { paymentProfile } from "../components/PaymentProfile/SmartPaymentProfile.lang.en";
import { userProfile } from "../components/UserProfile/SmartUserProfile.lang.en";
import { creditCardDisplay } from "../components/Common/CreditCardDisplay/SubCreditCardDisplay.lang.en";
import { page404 } from "../components/404/SmartPage404.lang.en";
import { CookiesBanner } from "../components/CookiesManager/CookiesBanner.lang.en";
import { generatedImageDashboard } from "../components/GeneratedImageDashboard/SmartGeneratedImageDashboard.jsx.lang.en";
import { favorites } from "../components/Favorites/SmartFavorites.lang.en.js";
import { subImageDashboard } from "../components/Common/ImageDashboard/SubImageDashboard.lang.en.js";
import { uploadImage } from "../components/UploadImage/SmartUploadImage.lang.en.js";

export const en = {
  homepage,
  sliders,
  compatibilities,
  presentation,
  faq,
  common,
  creditCardForm,
  contact,
  faqPage,
  helpCenter,
  jobs,
  legalNotices,
  privacy,
  terms,
  forgotPassword,
  managePaymentMethods,
  payProfileForm1,
  payProfileForm2,
  payProfileForm3,
  paymentProfile,
  userProfile,
  creditCardDisplay,
  page404,
  CookiesBanner,
  generatedImageDashboard,
  subImageDashboard,
  favorites,
  uploadImage
};
