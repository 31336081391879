import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { isFuture } from "date-fns";
import { isMasterCard, isVisa, validCreditCard } from "../../validation/validations";
import { getCookie, getToken, setCookie } from "../../utilities/cookie";
import CreditCardForm from "./CreditCardForm/SubCreditCardForm";
import { COOKIES } from "../../constants/cookies";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes";
import { removeError, setError } from "../../store/actions/errors";
import { translations } from "../../translations/texts";
import { useState, useEffect } from "react";
import NomadsFetchRequests from "../../requests";



const SmartCreditCardInformation = () => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const billingAddress = useSelector((state) => state.user.paymentAddress);

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const username = useSelector((state) => `${state.user.userData.firstName} ${state.user.userData.lastName}`);

	const [cardNumber, setCardNumber] = useState("");
	const [cardNumberError, setCardNumberError] = useState("");
	const [cardCVV, setCardCVV] = useState("");
	const [cardCVVError, setCardCVVError] = useState("");
	const [cardExpiration, setCardExpiration] = useState("");
	const [cardExpirationError, setCardExpirationError] = useState("");
	const [updateCreditCardLoading, setUpdateCreditCardLoading] = useState(false);

	useEffect(() => {
		return () => {
			window.history.replaceState({}, document.title);
			dispatch(removeError);
		};
	}, []);

	const cardNumberChange = (e) => {
		setCardNumber(e.target.value);
	};

	const cardCVVChange = (e) => {
		setCardCVV(e.target.value);
	};

	const cardExpirationChange = (e) => {
		setCardExpiration(e.target.value);
	};

	const fragmentCardExpiration = (cardExpiration) => {
		const array = cardExpiration.split(" / ");
		const month = array[0];
		const year = array[1];

		return { array, month, year };
	};

	const validateCardNumber = () => {
		let valid = true;

		if (!cardNumber) {
			setCardNumberError(translations[selectedLanguage].common.required);
			valid = false;
		} else {
			
			const formattedCardNumber = cardNumber.replace(/\s/g, "");

			if (!validCreditCard(formattedCardNumber)) {
				setCardNumberError(translations[selectedLanguage].creditCardForm.invalidCardNumber);
				valid = false;
			}

			if (!isMasterCard(formattedCardNumber) && !isVisa(formattedCardNumber)) {
				setCardNumberError(translations[selectedLanguage].creditCardForm.invalidCardNumber);
				valid = false;
			}
		}

		return valid;
	};

	const validateCardCVV = () => {
		let valid = true;

		if (!cardCVV) {
			setCardCVVError(translations[selectedLanguage].common.required);
			valid = false;
		} else if (cardCVV.includes("_")) {
			setCardCVVError(translations[selectedLanguage].creditCardForm.invalidCVV);
			valid = false;
		}

		return valid;
	};

	const validateCardExpiration = () => {
		let valid = true;

		if (!cardExpiration) {
			setCardExpirationError(translations[selectedLanguage].common.required);
			valid = false;
		} else if (cardExpiration.includes("_")) {
			setCardExpirationError(translations[selectedLanguage].creditCardForm.invalidExpiration);
			valid = false;
		} else {
			const { month, year } = fragmentCardExpiration(cardExpiration);

			if (month > 12) {
				setCardExpirationError(translations[selectedLanguage].creditCardForm.invalidExpiration);
				valid = false;
			} else {
				const expiration = new Date(`20${year}`, month, 0);

				if (!isFuture(expiration)) {
					setCardExpirationError(translations[selectedLanguage].creditCardForm.cardExpired);
					valid = false;
				}
			}
		}

		return valid;
	};

	const validate = () => {
		const valid1 = validateCardNumber();

		if (valid1) {
			setCardNumberError("");
		}

		const valid2 = validateCardCVV();

		if (valid2) {
			setCardCVVError("");
		}

		const valid3 = validateCardExpiration();

		if (valid3) {
			setCardExpirationError("");
		}

		return valid1 && valid2 && valid3;
	};

	const submit = (e) => {
		e.preventDefault();

		if (validate()) {
			addCreditCard();
		}
	};

	const addCreditCard = async () => {
		setUpdateCreditCardLoading(true);

		const { month, year } = fragmentCardExpiration(cardExpiration);

		const formattedCardNumber = cardNumber.replace(/\s/g, "");

		const data = {
			pan: formattedCardNumber,
			expiry: year + month,
			// phone: billingAddress.phone_number,
			// email: billingAddress.email_address,
			// note: "testing",
			// name: billingAddress.name,
			// streetNumber: billingAddress.address_line1name,
			// street: billingAddress.address_line1number,
			// zipCode: billingAddress.postal_code
		};

		NomadsFetchRequests.moneris
			.addCard(getToken(), data)
			.then((response) => {
				setUpdateCreditCardLoading(false);
        //this needs to be changed waiting on a response
        // setCookie(COOKIES.PURCHASETOKEN, response.data.dataKey, 14, rememberMe);
        navigate(INTERNAL_ROUTES.HOME);
			})
			.catch((error) => {
				setUpdateCreditCardLoading(false);
				dispatch(setError(error));
			});

		// NomadsFetchRequests.account
		// .updateProfile({
		//   lastName,
		//   firstName,
		//   dateOfBirth,
		//   gender,
		//   token: getCookie(COOKIES.TOKEN),
		// })
		// .then(async () => {
		//   setUpdateProfileLoading(false);
		//   setComponentLoading(true);

		//   try {
		//     await setUserInfo();
		//     setComponentLoading(false);
		//     setEditMode(false);
		//   } catch (error) {
		//     dispatch(setError(error));
		//   }
		// })
		// .catch((error) => {
		//   setUpdateProfileLoading(false);
		//   dispatch(setError(error));
		// });

		// try {
		//   const tokeResponse = await bamboraTokenization(
		//     formattedCardNumber,
		//     month,
		//     year,
		//     cardCVV
		//   );

		//   token = tokeResponse?.data?.token;
		// } catch (error) {
		//   dispatch(setError(error));
		// }

		// if (token) {
		//   const userProfile = {
		//     billing: location.state.billingAddress,
		//     token: {
		//       name: username,
		//       code: token,
		//     },
		//     currencyCode: "USD",
		//     session: getCookie(COOKIES.TOKEN),
		//   };

		//   try {
		//     await profile.createProfile(userProfile);
		//     navigate(INTERNAL_ROUTES.USER.CART);
		//   } catch (error) {
		//     setUpdateCreditCardLoading(false);
		//     dispatch(setError(error));
		//   }
		// } else {
		//   dispatch(setError(translations[selectedLanguage].common.errorNoToken));
		// }
	};


	const goBack = (e) => {
		e.preventDefault();
		navigate(INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE)
	} 

	if (!location.state?.billingAddress) {
		return <Navigate to={INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE} replace />;
	}

	return (
		<div data-testid="container-credit-card-information" className="form-container">
			<div className="nomad-section-container">
				<div className="form-card">
					<CreditCardForm
						cardNumberError={cardNumberError}
						cardNumber={cardNumber}
						cardCVV={cardCVV}
						cardCVVError={cardCVVError}
						cardExpiration={cardExpiration}
						cardExpirationError={cardExpirationError}
						cardNumberChange={cardNumberChange}
						cardCVVChange={cardCVVChange}
						cardExpirationChange={cardExpirationChange}
						submit={submit}
						updateCreditCardLoading={updateCreditCardLoading}
						goBack={goBack}
					/>
				</div>
			</div>
		</div>
	);
};

export default SmartCreditCardInformation;
