import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../../constants/internalRoutes";
import { LANG_ABREVIATION } from "../../../constants/language";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/reducers/language";
import { translations } from "../../../translations/texts";
import store from "../../../store/store";
import { changeToken } from "../../../store/actions/tokenAmount";
import { FaLanguage, FaStore, FaShoppingCart, FaHouseUser, FaMedal} from "react-icons/fa";
import CONFIG from "../../../config";
import NomadsFetchRequests from "../../../requests";
import { getToken } from "../../../utilities/cookie";
import { signIn } from "../../../store/actions/user";


const SubNavbarLinks = ({ closeMenu, cartItems, isLoggedIn, openSidebar, user }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const token = useSelector((state) => state.changeToken.token);

	if (isLoggedIn) {
		const fetchTokenAmount = async () => {
			try {
				const response = await NomadsFetchRequests.nomadsAi.getTokenAmount(getToken());
				dispatch(changeToken(response.data.tokens));
			} catch (error) {
				console.error("Failed to fetch token amount:", error);
			}
		};
		fetchTokenAmount();
	}

	const handleLanguageChange = (language) => {
		localStorage.setItem("lang", LANG_ABREVIATION.language);
		dispatch(setLanguage(language));
	};

	const renderLanguageBtn = () => {
		return (
			<ul className="navbar-item lang-navbar-item" value="" defaultValue>
				{CONFIG.enableLanguageSwitch && (
					<li className="wrap-lang-dropdown" value="" defaultValue id="languageSelection">
						<FaLanguage />
						{selectedLanguage.toUpperCase()}
						<ul className="lang-dropdown">
							<li className="store lang-li" onClick={() => handleLanguageChange(LANG_ABREVIATION.EN)} id="englishSelection">
								{LANG_ABREVIATION.EN}
							</li>
							<li className="lang-li" onClick={() => handleLanguageChange(LANG_ABREVIATION.FR)} id="frenchSelection">
								{LANG_ABREVIATION.FR}
							</li>
						</ul>
					</li>
				)}
			</ul>
		);
	};

	return (
		<section data-testid="container-navbar-links" className="navbar-link-container">
			{renderLanguageBtn()}
			{!isLoggedIn && (
				<>
					<div className="wrap-navbar-button">
						<Link onClick={() => closeMenu()} className="navbar-item navbar-button navbar-sign-in-button" to={INTERNAL_ROUTES.USER.SIGN_IN}>
							{translations[selectedLanguage].common.loginLink}
						</Link>
					</div>
					<div className="wrap-navbar-button">
						<Link onClick={() => closeMenu()} className="navbar-item navbar-button" to={INTERNAL_ROUTES.USER.REGISTER}>
							{translations[selectedLanguage].common.signUpLink}
						</Link>
					</div>
				</>
			)}
			{isLoggedIn && (
				<>
					<div className="navbar-item-no-hover"><FaMedal/> {token}</div>
					<div
						className="navbar-item"
						onClick={() => {
							closeMenu();
							openSidebar();
						}}
						id="sidebarButton"
					>
						<FaHouseUser />
						{user.firstName + " " + user.lastName}
					</div>
				</>
			)}
		</section>
	);
};

export default SubNavbarLinks;
