import { configureStore } from "@reduxjs/toolkit";
import cart from "./reducers/cart";
import user from "./reducers/user";
import nomadsDb from "./reducers/nomadsDb";
import error from "./reducers/errors";
import language from "./reducers/language";
import CookiesManagerReducer from "./reducers/CookiesManager";
import changeTokenReducer from "./reducers/tokenAmount";

const store = configureStore({
  reducer: {
    cart,
    user,
    nomadsDb,
    error,
    language,
    CookiesManager: CookiesManagerReducer,
    changeToken: changeTokenReducer,
  },
});

export default store;
