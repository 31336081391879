import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { COOKIES } from "../../constants/cookies.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { signIn } from "../../store/actions/user.js";
import { setCookie } from "../../utilities/cookie.js";
import { validEmail } from "../../validation/validations.js";
import SubCheckboxInput from "../Common/Form/CheckboxInput/CheckboxInput.jsx";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton.jsx";
// import CognitoRedirect from "./CognitoRedirect/SubCognitoRedirect.jsx";
import { translations } from "../../translations/texts.js";
import "./SmartLogin.scss";
import SubGoogleMethod from "./CognitoRedirect/LogInMethods/SubGoogleMethod.js";
import SubFacebookMethod from "./CognitoRedirect/LogInMethods/SubFacebookMethod.js";

const SmartLogin = () => {
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const isLoggedIn = useSelector((state) => !!state.user.userData.id);
  const token = useSelector((state) => state.changeToken.token);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const signInUser = (token) => {
    
    NomadsFetchRequests.account
      .getProfile(token)
      .then((response) => {
        const user = {
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          gender: response.data.gender,
          dateOfBirth: response.data.dateofbirth | "",
          email: response.data.email,
          isSubscribed: response.data.isSubscribed,
          token,
        };
        dispatch(signIn(user));
      })
      .catch((error) => {
        setLoginLoading(false);
        dispatch(setError(error));
      });
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const rememberMeHandler = (e) => {
    setRememberMe(e.target.checked);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const valid = validate();

    if (valid) {
      setLoginLoading(true);
      NomadsFetchRequests.account
        .login(email, password)
        .then((response) => {
          signInUser(response.data.token);
          setCookie(COOKIES.TOKEN, response.data.token, 14, rememberMe);
        })
        .catch((error) => {
          dispatch(setError(error));
          setLoginLoading(false);
        });
    }
  };

  const validateEmail = () => {
    let valid = true;

    if (!email) {
      setEmailErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    } else if (!validEmail(email)) {
      setEmailErrorMessage(translations[selectedLanguage].common.invalid);
      valid = false;
    }

    return valid;
  };

  const validatePassword = () => {
    let valid = true;

    if (!password) {
      setPasswordErrorMessage(translations[selectedLanguage].common.required);
      valid = false;
    }

    return valid;
  };

  const validate = () => {
    const valid1 = validateEmail();

    if (valid1) {
      setEmailErrorMessage("");
    }

    const valid2 = validatePassword();

    if (valid2) {
      setPasswordErrorMessage("");
    }

    return valid1 && valid2;
  };

  useEffect(() => {
    return () => {
      dispatch(removeError);
    };
  }, []);

  if (isLoggedIn) {
    return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
  }

  return (
    <section data-testid="container-login" className="form-container">
      <div className="form-card">
        <h2>{translations[selectedLanguage].common.loginTitle}</h2>
        <form onSubmit={submitHandler} action="/login" method="POST">
          <SubTextInput
            name="email"
            className="form-input"
            type="email"
            placeholder={translations[selectedLanguage].common.email}
            onChange={changeEmail}
            value={email}
            id="login-email"
            errorMessage={emailErrorMessage}
          />
          <SubTextInput
            name="password"
            className="form-input"
            type="password"
            placeholder={translations[selectedLanguage].common.password}
            onChange={changePassword}
            value={password}
            id="login-password"
            errorMessage={passwordErrorMessage}
          />
          <SubLoadingButton
            type="submit"
            label={translations[selectedLanguage].common.loginButton}
            isLoading={loginLoading}
          />
        </form>
        <div className="rememberforgot-container">
          <div className="remember-container">
            <SubCheckboxInput
              name="rememberMe"
              id="remember-me"
              checked={rememberMe}
              onChange={rememberMeHandler}
              label={translations[selectedLanguage].common.rememberMe}
            />
          </div>
          <Link
            className="reset-password-link"
            to={INTERNAL_ROUTES.USER.FORGOT_PASSWORD}
            data-testid="forgot-password-btn"
          >
            {`${translations[selectedLanguage].common.forgotpassheader}?`}
          </Link>
        </div>
        <div className="signup-instead-container">
          <p className="signup-instead-title">
            {translations[selectedLanguage].common.signUpText}
          </p>
          &nbsp;
          <Link to={INTERNAL_ROUTES.USER.REGISTER} className="register-btn">
            {translations[selectedLanguage].common.signUpLink}
          </Link>
        </div>
        {/* <CognitoRedirect isDisabled={loginLoading} /> */}
        <div>
        <SubGoogleMethod isDisabled={loginLoading}/>
        </div>
        {/* <SubFacebookMethod /> */}
      </div>
    </section>
  );
};

export default SmartLogin;
