export const contact = {
  title: "Contact",
  address: "Address",
  email: "Email",
  call: "Call",
  forAnyComplaints: "For any complaints feel free to email us at",
  needSupport: "Need support?",
  deleteProfile: "To request to get your account deleted please email ",
  emailUsAt: "Email us at",
};
