import { actionTypes } from "../../constants/actionTypes";

const getTokenInitialState = () => {

    return {
        token: 0,
    }
    
};

const changeTokenReducer = (state = getTokenInitialState(), action) => {
    switch (action.type) {
        case actionTypes.CHANGE_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        default:
            return state;
    }
};

export default changeTokenReducer