import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { actionTypes } from "../constants/actionTypes.js";
import { COOKIES } from "../constants/cookies.js";
import { LANG_ABREVIATION } from "../constants/language.js";
import NomadsFetchRequests from "../requests/index.js";
import SubRoutes from "../routes/routes.jsx";
import { actualizeCart } from "../store/actions/cart.js";
import { removeError, setError } from "../store/actions/errors.js";
import { translations } from "../translations/texts.js";
import { getCookie, setCookie } from "../utilities/cookie.js";
import { getUrlParams } from "../utilities/url.js";
import SubBanner from "./Banner/SubBanner.jsx";
import SubGlobalLoading from "./Common/Loading/GlobalLoading/SubGlobalLoading.jsx";
import SubFooter from "./Footer/SubFooter.jsx";
import SmartNavbar from "./Navbar/SmartNavbar.jsx";
import SubSocialMedias from "./SocialMedias/SubSocialMedias.jsx";

import "./App.scss";
import "../styles/common/section.scss";
import "../styles/common/forms.scss";
import "../styles/common/datepicker.scss";
import CONFIG from "../config.js";
import CookiesBanner from "./CookiesManager/CookiesBanner.jsx";

const App = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const CookiesManagerState = useSelector((state) => state.CookiesManager.windowPopUp);

	const onMount = async () => {
		initLang();

		await cognitoLogin();
		await tokenCheck();

		setLoading(false);
		dispatch(actualizeCart);
	};

	useEffect(() => {
		onMount();

		return () => {
			dispatch(removeError);
		};
	}, []);

	const cognitoLogin = () => {
		return new Promise(async (resolve, reject) => {
			const cognitoCode = getUrlParams().code;

			if (cognitoCode) {
				try {
					const response = await NomadsFetchRequests.account.cognitoLogin(cognitoCode);

					setCookie(COOKIES.TOKEN, response.token);
					resolve();
				} catch (error) {
					dispatch(setError(error));
					setLoading(false);
					reject();
				}
			} else {
				// not logged in with cognito
				resolve();
			}
		});
	};

	const tokenCheck = () => {
		return new Promise(async (resolve, reject) => {
			const token = getCookie(COOKIES.TOKEN);

			if (token) {
				try {
					await NomadsFetchRequests.account.tokenCheck(token);
					const getProfileResponse = await NomadsFetchRequests.account.getProfile(token);

					const userDataObj = {
						id: getProfileResponse.data.id,
						firstName: getProfileResponse.data.firstName,
						lastName: getProfileResponse.data.lastName,
						gender: getProfileResponse.data.gender,
						dateOfBirth: getProfileResponse.data.dateofbirth | "",
						email: getProfileResponse.data.email,
						isSubscribed: getProfileResponse.data.isSubscribed,
						token,
					};

					dispatch({
						type: actionTypes.SIGN_IN,
						payload: userDataObj,
					});

					resolve();
				} catch (error) {
					dispatch(setError(error));
					setLoading(false);
					reject();
				}
			} else {
				// not logged in
				resolve();
			}
		});
	};

	const initLang = () => {
		if (!CONFIG.enableLanguageSwitch) {
			localStorage.setItem("lang", LANG_ABREVIATION.EN);
		} else {
			switch (localStorage.getItem("lang")) {
				case LANG_ABREVIATION.EN:
					localStorage.setItem("lang", LANG_ABREVIATION.EN);
					break;
				case LANG_ABREVIATION.FR:
					localStorage.setItem("lang", LANG_ABREVIATION.FR);
					break;
				default: {
					localStorage.setItem("lang", LANG_ABREVIATION.EN);
				}
			}
		}
	};

	if (loading) {
		return <SubGlobalLoading />;
	}

	return (
		<section data-testid="container-app" className="main-background-image">
			<BrowserRouter>
				<SmartNavbar />
				<SubBanner />
				<SubRoutes />
				<SubSocialMedias />
				<SubFooter />
			</BrowserRouter>
			{CookiesManagerState && <CookiesBanner />}
		</section>
	);
};

export default App;
