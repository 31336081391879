export const CURRENCY_CODES = {
  USD: "USD",
  CAD: "CAD",
};

export const ASSETS_HOST = "https://d1tewqb6xco1xb.cloudfront.net/assets/";

export const EXTERNAL_URLS = {
  VISA_CARD_IMAGE: `${ASSETS_HOST}images/creditCards/visa.svg`,
  MASTER_CARD_IMAGE: `${ASSETS_HOST}images/creditCards/mastercard.svg`,
  FACEBOOK: "https://www.facebook.com/livenomads",
  INSTAGRAM: "https://www.instagram.com/livenomads",
  TWITTER: "https://twitter.com/livenomads",
  LINKEDIN: "https://www.linkedin.com/company/livenomads",
  YOUTUBE: "https://www.youtube.com/channel/UC6TbrgYj1NX_-8rzqF0WzaA",
  EXCHANGE_RATE: "https://api.exchangerate.host/latest?base=CAD",
};

export const ASSETS_PATH = {
  HOMEPAGE_COMPATIBILITIES: {
    PICO_VR: `${ASSETS_HOST}images/homepageCompatibilities/Logo-Pico-VR.png`,
    META_VR: `${ASSETS_HOST}images/homepageCompatibilities/Logo-Meta-VR.png`,
    STEAM_VR: `${ASSETS_HOST}images/homepageCompatibilities/Logo-Steam-VR.png`,
    APPLE_VR: `${ASSETS_HOST}images/homepageCompatibilities/Logo-Apple-VR.png`,
  },
  HOMEPAGE_SLIDERS: [
    [
      `${ASSETS_HOST}images/homepageSlider/slide1/1.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide1/2.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide1/3.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide1/4.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide1/5.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide1/6.jpg`,
    ],
    [
      `${ASSETS_HOST}images/homepageSlider/slide2/1.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide2/2.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide2/3.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide2/4.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide2/5.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide2/6.jpg`,
    ],
    [
      `${ASSETS_HOST}images/homepageSlider/slide3/1.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/2.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/3.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/4.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/5.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/6.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/7.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/8.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/9.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/10.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/11.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/12.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/13.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/14.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/15.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/16.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide3/17.jpg`,
    ],
    [
      `${ASSETS_HOST}images/homepageSlider/slide4/1.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide4/2.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide4/3.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide4/4.jpg`,
      `${ASSETS_HOST}images/homepageSlider/slide4/5.jpg`,
    ],
  ],
  HOMEPAGE_BACKGROUND: `${ASSETS_HOST}images/homepageBackground/sky_wide.jpg`,
  NOMADS_LOGOS: {
    TEXT_AND_LOGO: `${ASSETS_HOST}images/nomadsLogos/nomadsTextAndlogo.png`,
  },
  COUNTRY_FLAGS: {
    USA: `${ASSETS_HOST}images/countryFlags/usaFlag.png`,
    CAD: `${ASSETS_HOST}images/countryFlags/canadianFlag.jpg`,
  },
  PROFILES: {
    RED: `${ASSETS_HOST}images/profile/red.png`,
    GREEN: `${ASSETS_HOST}images/profile/green.png`,
  },
  CAROUSEL: {
    ARROW_LEFT: `${ASSETS_HOST}images/carousel/arrow_left-white.png`,
    ARROW_RIGHT: `${ASSETS_HOST}images/carousel/arrow_right-white.png`,
    CHECKMARK: `${ASSETS_HOST}images/carousel/checkmark.png`,
  },
  FAVORITE: {
    IS_FAVORITE: `${ASSETS_HOST}images/favorites/heart-filled.webp`,
    IS_NOT_FAVORITE: `${ASSETS_HOST}images/favorites/heart-hollow.webp`
  },
  STORE: {
    APP_STORE_en: `${ASSETS_HOST}images/callToActionImages/applestoreen.png`,
    APP_STORE_fr: `${ASSETS_HOST}images/callToActionImages/applestorefr.png`,
    PLAY_STORE_en: `${ASSETS_HOST}images/callToActionImages/googlePlayen.png`,
    PLAY_STORE_fr: `${ASSETS_HOST}images/callToActionImages/googlePlayfr.png`,
    META_STORE_en: `${ASSETS_HOST}images/callToActionImages/metaQuestStoreen.png`,
    META_STORE_fr: `${ASSETS_HOST}images/callToActionImages/metaQuestStorefr.png`,
    DOWNLOAD_BUTTON_en: `${ASSETS_HOST}images/callToActionImages/callToActionen.png`,
    DOWNLOAD_BUTTON_fr: `${ASSETS_HOST}images/callToActionImages/callToActionfr.png`,
  }
};

export const REGEX = {
  VISA: new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/),
  MASTERCARD: new RegExp(
    /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/
  ),
};

export const COUNTRY = {
  US: "US",
  CA: "CA",
  GB: "GB",
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  NB: "Non-binary",
};

export const MAX_NAME_LENGTH = 35;

export const SORT = {
  ALPHA_ASC: "AlphaAsc",
  ALPHA_DSC: "AlphaDsc",
  NUMBER_OF_EPISODES_DSC: "NumberOfEpisodeDsc",
  NUMBER_OF_EPISODES_ASC: "NumberOfEpisodeAsc",
};
