export const contact = {
  title: "Contact",
  address: "Adresse",
  email: "Email",
  call: "Appeler",
  forAnyComplaints: "Pour une plainte, veuillez nous contacter par",
  needSupport: "Besoin de support?",
  deleteProfile: "Pour demander la suppression de votre compte, veuillez envoyer un e-mail",
  emailUsAt: "Contactez nous par",
};
