import { isPast } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { getToken } from "../../utilities/cookie.js";
import { getLocaleProp } from "../../utilities/strings.js";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading.jsx";
import "./SmartSharedImage.scss";
import { translations } from "../../translations/texts.js";
import { useParams } from "react-router-dom";
import SubImageDashboard from "../Common/ImageDashboard/SubImageDashboard.jsx";

const SmartSharedImage = () => {
	const [componentLoading, setComponentLoading] = useState(true);
	const [generatedImage, setGeneratedImage] = useState([]);
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [liked, setLiked] = useState(null);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);

	const dispatch = useDispatch();

	const { id } = useParams();
	// let generatedImage = {
	//     id: 26,
	//     userId: 222320466,
	//     imageSrc: "https://images.blockadelabs.com/images/imagine/M3_Digital_Painting_equirectangular-jpg_A_scorpion_playing_guitar_1267897256_11602839.jpg?ver=1",
	//     thumbSrc: "https://images.blockadelabs.com/thumbs/imagine/thumb_M3_Americana_Painting_thumb_Center_stage_at_a_979649964_11718883.jpg?ver=1",
	//     audioSrc: "https://replicate.delivery/yhqm/wAfhhLAEqVwsEyWk3o7x3firxfR75gMZg54KXBqAAyQxXrYmA/out.wav",
	//     prompt: "A scorpion playing guitar.",
	//     enhancedImagePrompt: null,
	//     enhancedAudioPrompt: null,
	//     styleId: 0,
	//     styleName: null,
	//     favourite: null,
	//     insertDate: null,
	//     createdAt: null
	// }

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);

	useEffect(() => {
		if (isLoggedIn) {
			Promise.all([NomadsFetchRequests.nomadsAi.getGeneration(id), NomadsFetchRequests.nomadsAi.getLikes()])
				.then(([userScenesResponse, likesResponse]) => {
					setGeneratedImage(userScenesResponse.data.scene);
					setLiked(likesResponse.data.likes)
				})
				.catch((error) => {
					dispatch(setError(error));
				})
				.finally(() => {
					setComponentLoading(false);
				});

			return () => {
				dispatch(removeError);
			};
		} else {
			Promise.all([NomadsFetchRequests.nomadsAi.getGeneration(id)])
				.then(([userScenesResponse, likesResponse]) => {
					setGeneratedImage(userScenesResponse.data.scene);
				})
				.catch((error) => {
					dispatch(setError(error));
				})
				.finally(() => {
					setComponentLoading(false);
				});

			return () => {
				dispatch(removeError);
			};
		}
	}, []);

	let formattedDate;

	if (generatedImage.createdAt) {
		formattedDate = generatedImage.createdAt.split("T")[0];
	}

	const isLiked = liked && liked.some((likedItem) => likedItem.id === id);
	const renderContent = () => {
		return (
			<>
				<div>
					<h2>{translations[selectedLanguage].generatedImageDashboard.myImages}</h2>
					<h2>{translations[selectedLanguage].generatedImageDashboard.uploadImages}</h2>
				</div>
				<div className="rentals-dashboard-rentals">
					<div className={`rentals-dashboard-rental isSelected`}>
						<SubImageDashboard
							id={id}
							favorite={generatedImage.favorite}
							thumbNail={generatedImage.thumbSrc}
							img={generatedImage.imageSrc}
							createdOn={formattedDate}
							prompt={generatedImage.prompt}
							audioSrc={generatedImage.audioSrc}
							isSelected={true}
							startFullscreen={true}
							like={isLiked}
						/>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="rentals-dashboard" id="rentals">
			{componentLoading && <SubComponentLoading />}
			{!componentLoading && renderContent()}
		</div>
	);
};

export default SmartSharedImage;
