import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import NomadsFetchRequests from "../../requests/index.js";
import { getToken } from "../../utilities/cookie.js";
import SubStepButtons from "../Common/StepButtons/StepButtons.jsx";
import SubPayProfileForm1 from "./Forms/PayProfileForm1/PayProfileForm1.jsx";
import SubPayProfileForm2 from "./Forms/PayProfileForm2/PayProfileForm2.jsx";
import SubPayProfileForm3 from "./Forms/PayProfileForm3/PayProfileForm3.jsx";
import { getUrlParams } from "../../utilities/url.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { paymentAdress } from "../../store/actions/user.js";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading.jsx";
import "./SmartPaymentProfile.scss";
import SubCircleSteps from "../Common/CircleSteps/SubCircleSteps.jsx";
import { useState, useEffect } from "react";
import { translations } from "../../translations/texts.js";

const SmartPaymentProfile = () => {
	const [step, setStep] = useState(1);
	const [addressLine1Input, setAddressLine1Input] = useState("");
	const [addressLine2Input, setAddressLine2Input] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [regionInput, setRegionInput] = useState("");
	const [countryInput, setCountryInput] = useState("");
	const [postalCodeInput, setPostalCodeInput] = useState("");
	const [phoneNumberInput, setPhoneNumberInput] = useState("");
	const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
	const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");
	const [cityErrorMessage, setCityErrorMessage] = useState("");
	const [regionErrorMessage, setRegionErrorMessage] = useState("");
	const [countryErrorMessage, setCountryErrorMessage] = useState("");
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState("");
	const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
	const [recaptchaVerified, setRecaptchaVerified] = useState(false);
	const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState("");
	const [isUpdate, setIsUpdate] = useState(false);
	const [componentLoading, setComponentLoading] = useState(true);
	const [updateLoading, setUpdateLoading] = useState(false);

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const userData = useSelector((state) => state.user.userData);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		onMount();

		return () => {
			dispatch(removeError);
		};
	}, []);

	const onMount = async () => {
		setComponentLoading(false);
	};

	const step1Valid = () => {
		const valid = validateAddressLine1();

		if (valid) {
			setAddressLine1ErrorMessage("");
			setAddressLine2ErrorMessage("");
		}

		return valid;
	};

	const step2Valid = () => {
		const valid1 = validateCity();

		if (valid1) {
			setCityErrorMessage("");
		}

		const valid2 = validateRegion();

		if (valid2) {
			setRegionErrorMessage("");
		}

		const valid3 = validateCountry();

		if (valid3) {
			setCountryErrorMessage("");
		}

		return valid1 && valid2 && valid3;
	};

	const step3Valid = () => {
		const valid1 = validatePostalCode();

		if (valid1) {
			setPostalCodeErrorMessage("");
		}

		const valid2 = validatePhoneNumber();

		if (valid2) {
			setPhoneNumberErrorMessage("");
		}

		const valid3 = validateRecaptcha();

		if (valid3) {
			setRecaptchaErrorMessage("");
		}

		return valid1 && valid2 && valid3;
	};

	const nextStep = () => {
		let valid = true;

		switch (step) {
			case 1: {
				valid = step1Valid();
				break;
			}
			case 2: {
				valid = step2Valid();
				break;
			}
			case 3: {
				valid = step3Valid();
				break;
			}
		}

		if (valid && step < 3) {
			setStep(step + 1);
		}

		return valid;
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const changeAddressLine1Input = (e) => {
		setAddressLine1Input(e.target.value);
	};

	const changeAddressLine2Input = (e) => {
		setAddressLine2Input(e.target.value);
	};

	const changeCity = (e) => {
		setCityInput(e.target.value);
	};

	const changePostalCode = (e) => {
		setPostalCodeInput(e.target.value);
	};

	const changePhoneNumber = (e) => {
		setPhoneNumberInput(e.target.value);
	};

	const showStep = () => {
		switch (step) {
			case 1:
				return (
					<>
						<SubPayProfileForm1
							changeAddressLine1Input={changeAddressLine1Input}
							changeAddressLine2Input={changeAddressLine2Input}
							addressLine1Input={addressLine1Input}
							addressLine2Input={addressLine2Input}
							addressLine1ErrorMessage={addressLine1ErrorMessage}
							addressLine2ErrorMessage={addressLine2ErrorMessage}
						/>
						<SubStepButtons haveNextStep nextStepHandler={nextStep} />
					</>
				);
			case 2:
				return (
					<>
						<SubPayProfileForm2
							changeCity={changeCity}
							selectCountry={selectCountry}
							selectRegion={selectRegion}
							cityInput={cityInput}
							regionInput={regionInput}
							countryInput={countryInput}
							cityErrorMessage={cityErrorMessage}
							regionErrorMessage={regionErrorMessage}
							countryErrorMessage={countryErrorMessage}
							showRegion={showRegion()}
						/>
						<SubStepButtons havePrevStep haveNextStep nextStepHandler={nextStep} prevStepHandler={prevStep} />
					</>
				);
			case 3:
				return (
					<>
						<SubPayProfileForm3
							changePostalCode={changePostalCode}
							changePhoneNumber={changePhoneNumber}
							postalCodeInput={postalCodeInput}
							phoneNumberInput={phoneNumberInput}
							postalCodeErrorMessage={postalCodeErrorMessage}
							phoneNumberErrorMessage={phoneNumberErrorMessage}
							setRecaptchaVerified={setRecaptchaVerifiedFunc}
							recaptchaErrorMessage={recaptchaErrorMessage}
						/>
						<SubStepButtons
							isLoading={isUpdate && updateLoading}
							haveLoadingButton={isUpdate}
							loadingButtonLabel={translations[selectedLanguage].common.submit}
							havePrevStep
							haveNextStep
							nextStepHandler={submitHandler}
							prevStepHandler={prevStep}
						/>
					</>
				);
		}

		return <Navigate to={INTERNAL_ROUTES.HOME} replace />;
	};

	const setRecaptchaVerifiedFunc = (recaptchaVerified) => {
		setRecaptchaVerified(recaptchaVerified);
	};

	const selectCountry = (countryInput) => {
		setCountryInput(countryInput);
	};

	const showRegion = () => {
		return countryInput === "CA" || countryInput === "US";
	};

	const selectRegion = (regionInput) => {
		setRegionInput(regionInput);
	};

	const validateAddressLine1 = () => {
		let valid = true;

		if (!addressLine1Input) {
			setAddressLine1ErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validateCity = () => {
		let valid = true;

		if (!cityInput) {
			setCityErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validateRegion = () => {
		let valid = true;

		if (!showRegion()) {
			return valid;
		}

		if (!regionInput) {
			setRegionErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validateCountry = () => {
		let valid = true;

		if (!countryInput) {
			setCountryErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validatePostalCode = () => {
		let valid = true;

		if (!postalCodeInput) {
			setPostalCodeErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validatePhoneNumber = () => {
		let valid = true;

		if (!phoneNumberInput) {
			setPhoneNumberErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const validateRecaptcha = () => {
		let valid = true;

		if (!recaptchaVerified) {
			setRecaptchaErrorMessage(translations[selectedLanguage].common.required);
			valid = false;
		}

		return valid;
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (nextStep()) {
			const billingAddress = {
				name: `${userData.firstName} ${userData.lastName}`,
				addressLine1Number: addressLine1Input.match(/\d+/g)?.join("") || "",
				address_line1name: addressLine1Input.match(/[a-zA-Z]+/g)?.join("") || "",
				address_line2: addressLine2Input,
				city: cityInput,
				province: regionInput,
				country: countryInput,
				postal_code: postalCodeInput,
				phone_number: phoneNumberInput,
				email_address: userData.email,
			};

			if (!showRegion()) {
				delete billingAddress.province;
			}

			dispatch(paymentAdress(billingAddress));

			if (isUpdate) {
				setUpdateLoading(true);
				try {
					await NomadsFetchRequests.moneris.profile.updateProfile(getToken(), {
						profileCode: getUrlParams().id,
						profile: {
							billing: billingAddress,
						},
					});
					navigate(INTERNAL_ROUTES.PAYMENT.MANAGE_PAYMENT_METHODS);
				} catch (error) {
					dispatch(setError(error));
					setUpdateLoading(false);
				}
			} else {
				navigate(INTERNAL_ROUTES.PAYMENT.PAYMENT, {
					state: { billingAddress },
				});
			}
		}
	};

	const jumpToStep = (step) => {
		setStep(step);
	};

	const renderContent = () => {
		return (
			<>
				<div className="payment-profile-title-wrap">
					<h2>{translations[selectedLanguage].paymentProfile.billingInformation}</h2>
					<SubCircleSteps step={step} changeStep={(number) => jumpToStep(number)} />
				</div>
				{showStep()}
			</>
		);
	};

	const renderWrap = () => {
		return (
			<section data-testid="container-payment-profile" className="form-container">
				<div className="form-card payment-profile-wrap">
					{componentLoading && <SubComponentLoading />}
					{!componentLoading && renderContent()}
				</div>
			</section>
		);
	};

	if (isLoggedIn) {
		return renderWrap();
	} else {
		return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
	}
};

export default SmartPaymentProfile;
